import React, { useState } from 'react';

import './style.scss';

const initialState = new Date().getUTCFullYear();

const Bottom = () => {
  const [year] = useState(initialState);

  return (
    <footer className="footer">
      <p className="ml-3">&copy; {year > 2022 ? `2022 - ${year}` : year}</p>
    </footer>
  );
};

export default Bottom;
