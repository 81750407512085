/* eslint-disable */
import React, { useState, useContext } from 'react';
import { Context } from 'context/AuthContext';
import { dateFormat } from 'helpers/Date.Helper';
import { getStringFromPrice } from 'pages/PedidoMateriais/ContentPedido/_constants';
import { Button } from 'react-bootstrap';
require('process');

import './styles.scss';

const SummarySolicitacao = (props) => {
  let pedido = props.pedido;
  let solicitacao = props.solicitacao;

  let valorTotal =
    (solicitacao?.valorDiagramacao ?? 0) +
    (solicitacao?.valorImpressao ?? 0) +
    (solicitacao?.valorFrete ?? 0);

  if (!pedido.pedidoId) return null;
  const { showLoader } = useContext(Context);

  function toDataURL(url) {
    showLoader(true);
    return fetch(url)
      .then((response) => {
        return response.blob();
      })
      .then((blob) => {
        return URL.createObjectURL(blob);
      });
  }
  async function forceDownload(url, tag) {
    url = url.split('?')[0];

    var urlsplit = url.split('/');

    var fileName = tag + '_' + urlsplit[urlsplit.length - 1];

    var url = process.env.REACT_APP_API_ROOT + 'arquivo/Download?url=' + url;

    const a = document.createElement('a');
    a.href = await toDataURL(url);
    a.download = fileName;
    document.body.appendChild(a);
    a.click();
    document.body.removeChild(a);
    showLoader(false);
  }

  return (
    <div className="summary-informacoes">
      <h3>Informações</h3>
      <table className="table table-striped">
        <tbody>
          {solicitacao.solicitacaoId > 0 && (
            <tr>
              <td className="table-row-title">Nro</td>
              <td>{solicitacao.solicitacaoId}</td>
            </tr>
          )}
          {pedido && pedido.pedidoTipo && (
            <tr>
              <td className="table-row-title">Tipo</td>
              <td>{pedido.pedidoTipo?.nome}</td>
            </tr>
          )}
          {solicitacao.materialComunicacao && (
            <tr>
              <td className="table-row-title">Material</td>
              <td>{solicitacao.materialComunicacao.nome}</td>
            </tr>
          )}
          {pedido && pedido.loja && (
            <tr>
              <td className="table-row-title">Cliente</td>
              <td>{pedido.loja.razaoSocial}</td>
            </tr>
          )}
          {pedido && pedido.loja && pedido.loja.cnpj?.length > 0 && (
            <tr>
              <td className="table-row-title">CNPJ</td>
              <td>{pedido.loja.cnpj}</td>
            </tr>
          )}
          {pedido && pedido.loja && pedido.loja.regional && (
            <tr>
              <td className="table-row-title">Regional</td>
              <td>{pedido.loja.regional?.nome}</td>
            </tr>
          )}
          {(solicitacao.valorDiagramacao ?? 0) > 0 && (
            <tr>
              <td className="table-row-title">Valor Diagramação</td>
              <td>
                {solicitacao.valorDiagramacao.toLocaleString('pt-br', {
                  style: 'currency',
                  currency: 'BRL',
                })}
              </td>
            </tr>
          )}
          {(solicitacao.valorImpressao ?? 0) > 0 && (
            <tr>
              <td className="table-row-title">Valor Produção</td>
              <td>
                {solicitacao.valorImpressao.toLocaleString('pt-br', {
                  style: 'currency',
                  currency: 'BRL',
                })}
              </td>
            </tr>
          )}
          {(solicitacao.valorFrete ?? 0) > 0 && (
            <tr>
              <td className="table-row-title">Valor Frete</td>
              <td>
                {solicitacao.valorFrete.toLocaleString('pt-br', {
                  style: 'currency',
                  currency: 'BRL',
                })}
              </td>
            </tr>
          )}
          {valorTotal > 0 && (
            <tr>
              <td className="table-row-title">Valor Total</td>
              <td>
                {valorTotal.toLocaleString('pt-br', {
                  style: 'currency',
                  currency: 'BRL',
                })}
              </td>
            </tr>
          )}
          {pedido && pedido.loja && pedido.loja.canal && (
            <tr>
              <td className="table-row-title">Canal</td>
              <td>{pedido.loja.canal?.nome}</td>
            </tr>
          )}
          {pedido && pedido.loja && pedido.loja.rede && (
            <tr>
              <td className="table-row-title">Rede</td>
              <td>{pedido.loja.rede?.nome}</td>
            </tr>
          )}
          {pedido && pedido.solicitante && (
            <tr>
              <td className="table-row-title">Solicitante</td>
              <td>{pedido.solicitante.nome}</td>
            </tr>
          )}
          {solicitacao.solicitacaoDados &&
            solicitacao.solicitacaoDados.motivoAcao && (
              <tr>
                <td className="table-row-title">Público Alvo do Material</td>
                <td>
                  {solicitacao.solicitacaoDados.motivoAcao.nome}
                  {solicitacao.solicitacaoDados.outroMotivo?.length > 0 &&
                    solicitacao.solicitacaoDados.outroMotivo}
                </td>
              </tr>
            )}
          {solicitacao.solicitacaoDados &&
            solicitacao.solicitacaoDados.dataVeiculacaoInicio && (
              <tr>
                <td className="table-row-title">Início da Vigência</td>
                <td>
                  {dateFormat(
                    solicitacao.solicitacaoDados.dataVeiculacaoInicio
                  )}
                </td>
              </tr>
            )}
          {solicitacao.solicitacaoDados &&
            solicitacao.solicitacaoDados.dataVeiculacaoFim && (
              <tr>
                <td className="table-row-title">Fim da Vigência</td>
                <td>
                  {dateFormat(solicitacao.solicitacaoDados.dataVeiculacaoFim)}
                </td>
              </tr>
            )}
          {solicitacao.solicitacaoDados &&
            solicitacao.solicitacaoDados.nomeFantasia && (
              <tr>
                <td className="table-row-title">Nome Fantasia</td>
                <td>{solicitacao.solicitacaoDados.nomeFantasia}</td>
              </tr>
            )}
          {solicitacao.solicitacaoDados &&
            solicitacao.solicitacaoDados.quantidadeLojasParticipantes > 0 && (
              <tr>
                <td className="table-row-title">Qtd. Lojas Participantes</td>
                <td>
                  {solicitacao.solicitacaoDados.quantidadeLojasParticipantes}
                </td>
              </tr>
            )}
          {solicitacao.solicitacaoDados &&
            solicitacao.solicitacaoDados.quantidadeImpressosLoja > 0 && (
              <tr>
                <td className="table-row-title">Qtd. de Impressos por Loja</td>
                <td>{solicitacao.solicitacaoDados.quantidadeImpressosLoja}</td>
              </tr>
            )}
          {solicitacao.solicitacaoTamanhos &&
            solicitacao.solicitacaoTamanhos.length > 0 && (
              <tr>
                <td className="table-row-title">Tamanhos (L x A)</td>
                <td>
                  {solicitacao.solicitacaoTamanhos.map((tamanho, index) => {
                    return (
                      <div key={index}>
                        {tamanho.largura +
                          'x' +
                          tamanho.altura +
                          tamanho.unidadeTamanho}
                      </div>
                    );
                  })}
                </td>
              </tr>
            )}
          {solicitacao.solicitacaoAcaoPromocionais &&
            solicitacao.solicitacaoAcaoPromocionais.length > 0 && (
              <tr>
                <td className="table-row-title">Ações Promocionais</td>
                <td>
                  {solicitacao.solicitacaoAcaoPromocionais.map((acao) => {
                    return (
                      <div>
                        {acao.acaoPromocional.texto +
                          ': ' +
                          acao.solicitacaoAcaoPromocionalValores &&
                          acao.solicitacaoAcaoPromocionalValores.length > 0 &&
                          acao.solicitacaoAcaoPromocionalValores.map(
                            (acaoValor) => {
                              return (
                                acaoValor.acaoPromocionalCampo.texto +
                                ': ' +
                                acaoValor.valor +
                                ' '
                              );
                            }
                          )}
                      </div>
                    );
                  })}
                </td>
              </tr>
            )}
          {solicitacao.solicitacaoDados &&
            solicitacao.solicitacaoDados.observacoesGerais && (
              <tr>
                <td className="table-row-title">Observações</td>
                <td>
                  <span className="text-format">
                    {solicitacao.solicitacaoDados.observacoesGerais}
                  </span>
                </td>
              </tr>
            )}

          {solicitacao.solicitacaoDados &&
            solicitacao.solicitacaoDados.extensaoArquivo && (
              <tr>
                <td className="table-row-title">Extensão do Arquivo</td>
                <td>{solicitacao.solicitacaoDados.extensaoArquivo}</td>
              </tr>
            )}
          {solicitacao.solicitacaoDados.urlAnexoObservacoesGerais?.length >
            0 && (
            <tr>
              <td>Observações Anexo</td>
              <td>
                <a
                  href={`${solicitacao.solicitacaoDados.urlAnexoObservacoesGerais}`}
                  target="_blank"
                >
                  Download
                </a>
              </td>
            </tr>
          )}

          {solicitacao.solicitacaoDados && (
            <tr>
              <td className="table-row-title">Logo</td>
              <td>
                {solicitacao.solicitacaoDados.possuiLogo &&
                pedido &&
                pedido.loja &&
                pedido.loja.urlLogo ? (
                  <>
                    <a
                      href={pedido.loja.urlLogo}
                      target="_blank"
                      rel="noopener"
                    >
                      <img
                        className="resumo-logo"
                        src={pedido.loja.urlLogo}
                      ></img>
                    </a>
                    <Button
                      onClick={() => forceDownload(pedido.loja.urlLogo)}
                      download="Logo"
                    >
                      Baixar Logo
                    </Button>
                  </>
                ) : (
                  'Não Possui'
                )}
              </td>
            </tr>
          )}
          {solicitacao.solicitacaoDados &&
            (solicitacao.solicitacaoDados.possuiRodape ||
              solicitacao.solicitacaoRodape) && (
              <tr>
                <td className="table-row-title">Rodapé</td>
                <td>
                  {solicitacao.solicitacaoDados.possuiRodape &&
                  pedido &&
                  pedido.loja &&
                  pedido?.loja?.urlRodape ? (
                    <>
                      <a
                        href={pedido.loja.urlRodape}
                        target="_blank"
                        rel="noopener"
                      >
                        <img
                          className="resumo-rodape-img"
                          src={pedido.loja.urlRodape}
                          onClick={() => forceDownload(pedido.loja.urlRodape)}
                        ></img>
                      </a>
                      <Button
                        onClick={() => forceDownload(pedido.loja.urlRodape)}
                        download="Rodapé"
                      >
                        Baixar Rodapé
                      </Button>
                    </>
                  ) : solicitacao.solicitacaoRodape ? (
                    solicitacao.solicitacaoRodape.map((item, idx) => {
                      return (
                        <div key={idx} className="resumo-rodape">
                          Loja {idx + 1}: {item.endereco}
                        </div>
                      );
                    })
                  ) : (
                    'Não Possui'
                  )}
                </td>
              </tr>
            )}
          {solicitacao.solicitacaoDados &&
            solicitacao.solicitacaoDados.formaPagamento && (
              <tr>
                <td className="table-row-title">Formas de Pagamento</td>
                <td>
                  {solicitacao.solicitacaoDados.formaPagamento}
                  {solicitacao.solicitacaoDados.OutraFormaPagamento
                    ? ',' + solicitacao.solicitacaoDados.OutraFormaPagamento
                    : ''}
                </td>
              </tr>
            )}
        </tbody>
      </table>

      {solicitacao.solicitacaoDados &&
        solicitacao.solicitacaoDados.layoutArte && (
          <div>
            <h3>Layout</h3>
            <div className="img-layout">
              <label>{solicitacao.solicitacaoDados.layoutArte.descricao}</label>
              <img
                src={solicitacao.solicitacaoDados.layoutArte.urlImagem}
              ></img>
            </div>
          </div>
        )}

      {solicitacao.solicitacaoDados &&
        (solicitacao.solicitacaoDados.site?.length > 0 ||
          solicitacao.solicitacaoDados.facebook?.length > 0 ||
          solicitacao.solicitacaoDados.instagram?.length > 0 ||
          solicitacao.solicitacaoDados.telefoneContato?.length > 0 ||
          solicitacao.solicitacaoDados.telefoneDelivery?.length > 0) && (
          <div>
            <h3>Dados da Loja</h3>
            <table className="table table-striped">
              <tbody>
                {solicitacao.solicitacaoDados.site?.length > 0 && (
                  <tr>
                    <td className="table-row-title">Site</td>
                    <td>{solicitacao.solicitacaoDados.site}</td>
                  </tr>
                )}
                {solicitacao.solicitacaoDados.facebook?.length > 0 && (
                  <tr>
                    <td className="table-row-title">Facebook</td>
                    <td>{solicitacao.solicitacaoDados.facebook}</td>
                  </tr>
                )}
                {solicitacao.solicitacaoDados.instagram?.length > 0 && (
                  <tr>
                    <td className="table-row-title">Instagram</td>
                    <td>{solicitacao.solicitacaoDados.instagram}</td>
                  </tr>
                )}
                {solicitacao.solicitacaoDados.telefoneContato?.length > 0 && (
                  <tr>
                    <td className="table-row-title">Telefone Contato</td>
                    <td>{solicitacao.solicitacaoDados.telefoneContato}</td>
                  </tr>
                )}
                {solicitacao.solicitacaoDados.telefoneDelivery?.length > 0 && (
                  <tr>
                    <td className="table-row-title">Telefone Delivery</td>
                    <td>{solicitacao.solicitacaoDados.telefoneDelivery}</td>
                  </tr>
                )}
              </tbody>
            </table>
          </div>
        )}

      {solicitacao.solicitacaoProdutos &&
        solicitacao.solicitacaoProdutos.length > 0 && (
          <div className="resumo-grid-produtos">
            <h3>Produtos</h3>
            {solicitacao.solicitacaoProdutos.map(
              (solicitacaoProduto, index) => {
                return (
                  <div className="row row-striped" key={index}>
                    <div className="col-lg-2">
                      <div className="row">
                        <div className="col-lg-12">
                          {solicitacaoProduto.urlImagem &&
                            solicitacaoProduto.urlImagem.length > 0 && (
                              <img
                                className="img-fluid"
                                src={solicitacaoProduto.urlImagem}
                              />
                            )}
                        </div>
                      </div>
                    </div>

                    <div className="col-lg-6">
                      <div className="row">
                        <div className="col-lg-12">
                          <label>Código</label>
                          {solicitacaoProduto.produto.codigo}
                        </div>
                      </div>

                      <div className="row">
                        <div className="col-lg-12">
                          <label>Produto</label>
                          {solicitacaoProduto.produto.nome}
                        </div>
                      </div>

                      <div className="row">
                        <div className="col-lg-12">
                          <label>Descrição no Encarte</label>
                          {solicitacaoProduto.descricao}
                        </div>
                      </div>

                      {solicitacaoProduto.observacao && (
                        <div className="row">
                          <div className="col-lg-12 informacoes-observacao">
                            <label>Observação</label>
                            {solicitacaoProduto.observacao}
                          </div>
                        </div>
                      )}

                      {(
                        solicitacaoProduto.solicitacaoProdutoDetalhamentos || []
                      ).length > 0 && (
                        <div className="row">
                          <div className="col-lg-12">
                            <label>Detalhes</label>
                            {(
                              (
                                solicitacaoProduto.solicitacaoProdutoDetalhamentos ||
                                []
                              ).map((x) => {
                                return solicitacaoProduto.produto?.produtoDetalhamentos.find(
                                  (detalhamento) =>
                                    detalhamento.produtoDetalhamentoId ===
                                    x.produtoDetalhamentoId
                                );
                              }) || []
                            ).map((detalhamento, index) => {
                              return (
                                <span key={index} className="d-block">
                                  {detalhamento.descricao}
                                </span>
                              );
                            })}
                          </div>
                        </div>
                      )}
                    </div>

                    <div className="col-lg-2">
                      <div className="row">
                        <div className="col-lg-12">
                          <label>Unidade</label>
                          {solicitacaoProduto.unidade}
                        </div>
                      </div>
                    </div>

                    <div className="col-lg-2">
                      <div className="row">
                        {!solicitacaoProduto.oferta && (
                          <div className="col-lg-12">
                            <label>Preço</label>
                            <span className="produto-preco">
                              R$ {getStringFromPrice(solicitacaoProduto.preco)}
                            </span>
                          </div>
                        )}

                        {solicitacaoProduto.oferta && (
                          <div className="col-lg-12">
                            <label>Preço OFERTA</label>
                            <span className="produto-preco">
                              de R${' '}
                              {getStringFromPrice(solicitacaoProduto.precoDe)}
                            </span>{' '}
                            <span className="produto-preco">
                              por R${' '}
                              {getStringFromPrice(solicitacaoProduto.preco)}
                            </span>
                          </div>
                        )}
                      </div>

                      {solicitacaoProduto.destaque && (
                        <div className="row">
                          <div className="col-lg-12">
                            <label className="bg-primary text-white d-block text-center">
                              <i>Destacar</i>
                            </label>
                          </div>
                        </div>
                      )}
                    </div>
                  </div>
                );
              }
            )}
          </div>
        )}
    </div>
  );
};

export default SummarySolicitacao;
