import React from 'react';
import { Button } from 'react-bootstrap';

import './styles.scss';

const TabHeader = (props) => {
  const { title, handleExportClick, parentRef, parentKey } = props;
  return (
    <div className="tab-header">
      <div className="header-content">
        <img
          src="https://maxi-midias.s3.us-west-2.amazonaws.com/logo/mccain.png"
          alt="logo"
          className="img-logo"
        />
        <h1>{title.toUpperCase()}</h1>
        <Button
          onClick={() => {
            handleExportClick(parentRef, parentKey);
          }}
        >
          Exportar página
        </Button>
      </div>
      <div className="gradient" />
    </div>
  );
};

export default TabHeader;
