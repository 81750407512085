/* eslint-disable */
import React from 'react';

import './styles.scss';
// import BrandColor from 'assets/imgs/logo_cliente.png';
// import BrandWhite from 'assets/imgs/logo_branco.png';

const Brand = (props) => {
  const { white } = props;
  return (
    <img
      className="brand"
      src={
        white
          ? 'https://maxi-midias.s3.us-west-2.amazonaws.com/logo/mccain.png'
          : 'https://maxi-midias.s3.us-west-2.amazonaws.com/logo/mccain.png'
      }
      alt="McCain"
    />
  );
};

export default Brand;
