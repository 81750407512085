/* eslint-disable jsx-a11y/anchor-is-valid */
import React from 'react';

import HeaderApp from 'components/Header/HeaderApp';
import Bottom from 'components/Bottom';
import PageTitle from 'components/_base/PageTitle';

import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faQuestionCircle } from '@fortawesome/free-solid-svg-icons';
import ContentHistoricoPedidos from './ContentHistoricoPedidos';

const App = () => {
  return (
    <>
      <HeaderApp />
      <PageTitle title="Histórico de pedidos" />
      <div className="text-center demanda-help-link">
        <span>
          <FontAwesomeIcon icon={faQuestionCircle} />
          &nbsp; Se tiver dúvidas para preencher essa página,{' '}
          <a href="#">CLIQUE AQUI</a>
        </span>
      </div>
      <hr />
      <ContentHistoricoPedidos />
      <Bottom />
    </>
  );
};

export default App;
