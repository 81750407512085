/* eslint-disable no-restricted-globals */
/* eslint-disable eqeqeq */
/* eslint-disable one-var */
/* eslint-disable spaced-comment */
/* eslint-disable prefer-const */
/* eslint-disable prefer-template */
const httpStatusCode = {
  ok: 200,
  badRequest: 400,
  internalServerError: 500,
};

const tabs = {
  tabCliente: 'cliente',
  tabAddCliente: 'adicionar-cliente',
  tabLayout: 'layout',
  tabTamanho: 'tamanho',
  tabDadosAcao: 'dados-acao',
  tabResumo: 'resumo',
  tabSucesso: 'sucesso',
};

const allTabs = [
  {
    nome: 'tabCliente',
    url: 'cliente',
    icone: 'fa-user',
    texto: 'Escolha o Cliente',
  },
  {
    nome: 'tabAdicionarCliente',
    url: 'adicionar-cliente',
    icone: 'fa-user',
    texto: 'Cadastrar novo cliente',
  },
  {
    nome: 'tabLayout',
    url: 'layout',
    texto: 'Escolha o Layout',
  },
  {
    nome: 'tabTamanho',
    url: 'tamanho',
    icone: 'fa-user',
    texto: 'Selecione o(s) tamanho(s)',
  },
  {
    nome: 'tabDadosAcao',
    url: 'dados-acao',
    icone: 'fa-user',
    texto: 'Escolha a ação promocional',
  },
  {
    nome: 'tabResumo',
    url: 'resumo',
    icone: 'fa-user',
    texto: 'Resumo das solicitações',
  },
  {
    nome: 'tabSucesso',
    url: 'sucesso',
    icone: 'fa-user',
    texto: 'Solicitações criadas com sucesso!',
  },
];

const entregaTabs = {
  tabDigital: 'digital',
  tabFisica: 'fisica',
};

const solicitacaoTabs = {
  tabLayout: 'layout',
  tabProdutos: 'produtos',
  tabDados: 'dados',
};

const passos = {
  passoCliente: { nro: 0, tab: tabs.tabCliente },
  passoDados: {
    nro: 1,
    tab: tabs.tabSolicitacoes,
    solicitacaoTab: solicitacaoTabs.tabDados,
  },
  passoLayout: {
    nro: 2,
    tab: tabs.tabSolicitacoes,
    solicitacaoTab: solicitacaoTabs.tabLayout,
  },
  passoProdutos: {
    nro: 3,
    tab: tabs.tabSolicitacoes,
    solicitacaoTab: solicitacaoTabs.tabProdutos,
  },
  passoEntrega: { nro: 4, tab: tabs.tabEntrega },
  passoMateriais: { nro: 5, tab: tabs.tabMateriais },
};

const entregaTipoNomes = {
  lojaLoja: 'LojaLoja',
  centroDistribuicao: 'CentroDistribuicao',
  outro: 'Outro',
  digital: 'Digital',
};

const unidadeMedidas = {
  cm: 'CM',
  px: 'PX',
};

const cadastroItemKeys = {
  Dados_Veiculacao: 'Dados_Veiculacao',
  Dados_DataEnvioArte: 'Dados_DataEnvioArte',
  Dados_Motivo: 'Dados_Motivo',
  Dados_QuantidadeImpressosLoja: 'Dados_QuantidadeImpressosLoja',
  Dados_NomeFantasia: 'Dados_NomeFantasia',
  Dados_ExtensaoArquivo: 'Dados_ExtensaoArquivo',
  Dados_Tamanho_Unico: 'Dados_Tamanho_Unico',
  Dados_Site: 'Dados_Site',
  Dados_Facebook: 'Dados_Facebook',
  Dados_Instagram: 'Dados_Instagram',
  Dados_TelefoneContato: 'Dados_TelefoneContato',
  Dados_TelefoneDelivery: 'Dados_TelefoneDelivery',
  Dados_Logo: 'Dados_Logo',
  Dados_Rodape: 'Dados_Rodape',
  Produtos: 'Produtos',
  Dados_QuantidadeLojasParticipantes: 'Dados_QuantidadeLojasParticipantes',
  Dados_Tempo: 'Dados_Tempo',
  Dados_Tamanhos_Multi: 'Dados_Tamanhos_Multi',
  Dados_Observacao: 'Dados_Observacao',
};

const checkUrlImage = (url) => {
  return url.match(/\.(jpeg|jpg|gif|png)$/) != null;
};

const getStringFromDate = (data) => {
  if (!data) return '';

  let parsedData = new Date(data);

  let dia = parsedData.getDate().toString(),
    diaF = dia.length == 1 ? '0' + dia : dia,
    mes = (parsedData.getMonth() + 1).toString(), //+1 pois no getMonth Janeiro começa com zero.
    mesF = mes.length == 1 ? '0' + mes : mes,
    anoF = parsedData.getFullYear();
  return diaF + '/' + mesF + '/' + anoF;
};

const getDateFromString = (str) => {
  if (!str || !str.length) return null;

  let parts = str.split('/');
  if (parts.length < 3) return null;

  return new Date(parts[2], parts[1] - 1, parts[0]);
};

const getDateFromStringJson = (obj) => {
  if (!obj) return null;

  let str = obj.toString();
  if (!str.length) return null;

  let parsedData = new Date(str);
  if (parsedData === 'Invalid Date') return null;

  if (isNaN(new Date(parsedData))) return null;

  return parsedData;
};

const compareString = (str, termo) => {
  let strNormalized = str
    .normalize('NFD')
    .replace(/[\u0300-\u036f]/g, '')
    .toLowerCase();
  let termoNormalized = termo
    .normalize('NFD')
    .replace(/[\u0300-\u036f]/g, '')
    .toLowerCase();

  return strNormalized.indexOf(termoNormalized) !== -1;
};

const getCampoObrigatorioClassName = (valor, obrigatorio) => {
  if (!obrigatorio) return '';

  if (valor && valor !== '' && valor !== '0' && valor !== 0) return '';

  return 'campo-obrigatorio';
};

const testeModel = {
  cliente: {
    lojaId: 46233,
    codigo: '7436493',
    razaoSocial: '3 PRIMOS RCADO E PADARIA LTDA',
    nomeFantasia: '3 PRIMOS RCADO E PADARIA LTDA',
    cnpj: '29171579000137',
    ie: null,
    regionalId: 43,
    canalId: 17,
    logradouro: 'Rua Doutor Sardinha',
    numero: '218',
    bairro: null,
    complemento: null,
    cep: '24240-660',
    cidadeId: 6807,
    ativo: true,
    enderecoVerificado: false,
    enderecoComProblema: false,
    redesSociais: null,
    site: null,
    urlLogo:
      'https://s3-us-west-2.amazonaws.com/midias-mccain1/HOM/Loja/Logo/image01.png?20210215144441',
    urlRodape: null,
    urlCartaoCliente: null,
    redeId: 11182,
    rede: null,
    regional: {
      regionalId: 43,
      nome: 'RIO DE JANEIRO',
      regionalSuperiorId: 69,
      cnpj: '01.838.723/0335-64',
      ie: 'I.E.',
      logradouro:
        'Av Embaixador Abelardo Bueno, 199 - Lot 1 Pal 46742 Sala 501',
      numero: null,
      bairro: 'JACAREPEGUA',
      complemento: null,
      cep: '22775-022',
      cidadeId: 6861,
      ativo: true,
      regionalSuperior: null,
      cidade: {
        cidadeId: 6861,
        nome: 'Rio de Janeiro',
        uf: 'RJ',
        capital: true,
        estado: null,
        ativo: true,
      },
    },
    canal: {
      canalId: 17,
      nome: 'PADARIAS',
      ativo: true,
    },
    cidade: {
      cidadeId: 6807,
      nome: 'Niteroi',
      uf: 'RJ',
      capital: false,
      estado: null,
      ativo: true,
    },
  },
  solicitacoes: [
    {
      materialComunicacaoId: 23,
      materialComunicacao: {
        materialComunicacaoId: 23,
        nome: 'BANNER ANIMADO',
        descricao: 'BANNER ANIMADO',
        materialComunicacaoTipoId: 4,
        minimoProdutos: 0,
        maximoProdutos: 0,
        diasInicio: 0,
        diasFim: 0,
        ativo: true,
        possuiProducao: false,
        possuiFrete: false,
        diasInicioDigital: 0,
        diasInicioCapital: 0,
        diasInicioInterior: 0,
        duracaoVigencia: 0,
        urlImagem: null,
        unidadeMedida: 'CM',
        precoObrigatorio: false,
        materialComunicacaoTipo: null,
        valorDiagramacaoMaterialComunicacao: null,
      },
    },
    {
      materialComunicacaoId: 7,
      materialComunicacao: {
        materialComunicacaoId: 7,
        nome: 'BANNER ESTÁTICO',
        descricao: 'BANNER',
        materialComunicacaoTipoId: 4,
        minimoProdutos: 3,
        maximoProdutos: 1,
        diasInicio: 0,
        diasFim: 0,
        ativo: true,
        possuiProducao: false,
        possuiFrete: false,
        diasInicioDigital: 0,
        diasInicioCapital: 0,
        diasInicioInterior: 0,
        duracaoVigencia: 0,
        urlImagem:
          'https://s3-us-west-2.amazonaws.com/midias-mccain1/HOM/MaterialComunicacao/BANNERTOTEN.png?20201201145501',
        unidadeMedida: 'CM',
        precoObrigatorio: false,
        materialComunicacaoTipo: null,
        valorDiagramacaoMaterialComunicacao: null,
      },
    },
    {
      materialComunicacaoId: 8,
      materialComunicacao: {
        materialComunicacaoId: 8,
        nome: 'EMAIL MARKETING',
        descricao: 'EMAIL',
        materialComunicacaoTipoId: 4,
        minimoProdutos: 3,
        maximoProdutos: 8,
        diasInicio: 0,
        diasFim: 0,
        ativo: true,
        possuiProducao: false,
        possuiFrete: false,
        diasInicioDigital: 0,
        diasInicioCapital: 0,
        diasInicioInterior: 0,
        duracaoVigencia: 0,
        urlImagem:
          'https://s3-us-west-2.amazonaws.com/midias-/HOM/MaterialComunicacao/DisplayL_mkpPERDIGAO.png?20201201152940',
        unidadeMedida: 'PX',
        precoObrigatorio: false,
        materialComunicacaoTipo: null,
        valorDiagramacaoMaterialComunicacao: null,
      },
    },
  ],
  // "tipoMateriais": [],
  tipoMateriais: [
    {
      materialComunicacaoTipoId: 3,
      nome: 'ENCARTES',
      ordem: 0,
      iconeMenu: null,
      title: null,
      ativo: false,
      materiaisComunicacao: [
        {
          materialComunicacaoId: 4,
          nome: 'BOX PARA ENCARTE',
          descricao: 'BOX',
          materialComunicacaoTipoId: 3,
          minimoProdutos: 3,
          maximoProdutos: 12,
          diasInicio: 0,
          diasFim: 0,
          ativo: true,
          possuiProducao: false,
          possuiFrete: false,
          diasInicioDigital: 0,
          diasInicioCapital: 0,
          diasInicioInterior: 0,
          duracaoVigencia: 0,
          urlImagem: null,
          unidadeMedida: 'CM',
          precoObrigatorio: false,
          materialComunicacaoTipo: null,
          valorDiagramacaoMaterialComunicacao: null,
        },
        {
          materialComunicacaoId: 3,
          nome: 'ENCARTE EXCLUSIVO',
          descricao: 'ENCARTE EXCLUSIVO IMPRESSO E ENTREGUE',
          materialComunicacaoTipoId: 3,
          minimoProdutos: 1,
          maximoProdutos: 18,
          diasInicio: 0,
          diasFim: 0,
          ativo: true,
          possuiProducao: true,
          possuiFrete: true,
          diasInicioDigital: 2,
          diasInicioCapital: 0,
          diasInicioInterior: 3,
          duracaoVigencia: 4,
          urlImagem:
            'https://s3-us-west-2.amazonaws.com/midias-mccain1/HOM/MaterialComunicacao/encarte.png?20201201145158',
          unidadeMedida: 'CM',
          precoObrigatorio: true,
          materialComunicacaoTipo: null,
          valorDiagramacaoMaterialComunicacao: null,
        },
        {
          materialComunicacaoId: 5,
          nome: 'LÂMINA',
          descricao: 'LÂMINA',
          materialComunicacaoTipoId: 3,
          minimoProdutos: 3,
          maximoProdutos: 8,
          diasInicio: 0,
          diasFim: 0,
          ativo: true,
          possuiProducao: true,
          possuiFrete: true,
          diasInicioDigital: 0,
          diasInicioCapital: 0,
          diasInicioInterior: 0,
          duracaoVigencia: 0,
          urlImagem: null,
          unidadeMedida: null,
          precoObrigatorio: false,
          materialComunicacaoTipo: null,
          valorDiagramacaoMaterialComunicacao: null,
        },
      ],
    },
    {
      materialComunicacaoTipoId: 4,
      nome: 'MATERIAIS DIGITAIS',
      ordem: 0,
      iconeMenu: null,
      title: null,
      ativo: false,
      materiaisComunicacao: [
        {
          materialComunicacaoId: 23,
          nome: 'BANNER ANIMADO',
          descricao: 'BANNER ANIMADO',
          materialComunicacaoTipoId: 4,
          minimoProdutos: 0,
          maximoProdutos: 0,
          diasInicio: 0,
          diasFim: 0,
          ativo: true,
          possuiProducao: false,
          possuiFrete: false,
          diasInicioDigital: 0,
          diasInicioCapital: 0,
          diasInicioInterior: 0,
          duracaoVigencia: 0,
          urlImagem: null,
          unidadeMedida: 'CM',
          precoObrigatorio: false,
          materialComunicacaoTipo: null,
          valorDiagramacaoMaterialComunicacao: null,
        },
        {
          materialComunicacaoId: 7,
          nome: 'BANNER ESTÁTICO',
          descricao: 'BANNER',
          materialComunicacaoTipoId: 4,
          minimoProdutos: 3,
          maximoProdutos: 1,
          diasInicio: 0,
          diasFim: 0,
          ativo: true,
          possuiProducao: false,
          possuiFrete: false,
          diasInicioDigital: 0,
          diasInicioCapital: 0,
          diasInicioInterior: 0,
          duracaoVigencia: 0,
          urlImagem:
            'https://s3-us-west-2.amazonaws.com/midias-mccain1/HOM/MaterialComunicacao/BANNERTOTEN.png?20201201145501',
          unidadeMedida: 'CM',
          precoObrigatorio: false,
          materialComunicacaoTipo: null,
          valorDiagramacaoMaterialComunicacao: null,
        },
        {
          materialComunicacaoId: 8,
          nome: 'EMAIL MARKETING',
          descricao: 'EMAIL',
          materialComunicacaoTipoId: 4,
          minimoProdutos: 3,
          maximoProdutos: 8,
          diasInicio: 0,
          diasFim: 0,
          ativo: true,
          possuiProducao: false,
          possuiFrete: false,
          diasInicioDigital: 0,
          diasInicioCapital: 0,
          diasInicioInterior: 0,
          duracaoVigencia: 0,
          urlImagem:
            'https://s3-us-west-2.amazonaws.com/midias-mccain1/HOM/MaterialComunicacao/DisplayL_mkpPERDIGAO.png?20201201152940',
          unidadeMedida: 'PX',
          precoObrigatorio: false,
          materialComunicacaoTipo: null,
          valorDiagramacaoMaterialComunicacao: null,
        },
        {
          materialComunicacaoId: 25,
          nome: 'POST PARA REDE SOCIAL ANIMADO',
          descricao: 'POST PARA REDE SOCIAL ANIMADO',
          materialComunicacaoTipoId: 4,
          minimoProdutos: 0,
          maximoProdutos: 0,
          diasInicio: 0,
          diasFim: 0,
          ativo: true,
          possuiProducao: false,
          possuiFrete: false,
          diasInicioDigital: 0,
          diasInicioCapital: 0,
          diasInicioInterior: 0,
          duracaoVigencia: 0,
          urlImagem: null,
          unidadeMedida: 'CM',
          precoObrigatorio: false,
          materialComunicacaoTipo: null,
          valorDiagramacaoMaterialComunicacao: null,
        },
        {
          materialComunicacaoId: 6,
          nome: 'POST PARA REDE SOCIAL ESTÁTICO',
          descricao: 'POST PARA REDE SOCIAL ESTÁTICO',
          materialComunicacaoTipoId: 4,
          minimoProdutos: 3,
          maximoProdutos: 8,
          diasInicio: 0,
          diasFim: 0,
          ativo: true,
          possuiProducao: false,
          possuiFrete: false,
          diasInicioDigital: 0,
          diasInicioCapital: 0,
          diasInicioInterior: 0,
          duracaoVigencia: 0,
          urlImagem: null,
          unidadeMedida: 'CM',
          precoObrigatorio: false,
          materialComunicacaoTipo: null,
          valorDiagramacaoMaterialComunicacao: null,
        },
        {
          materialComunicacaoId: 9,
          nome: 'VÍDEO ATÉ 30 SEG.',
          descricao: 'VÍDEO',
          materialComunicacaoTipoId: 4,
          minimoProdutos: 3,
          maximoProdutos: 8,
          diasInicio: 0,
          diasFim: 0,
          ativo: true,
          possuiProducao: false,
          possuiFrete: false,
          diasInicioDigital: 0,
          diasInicioCapital: 0,
          diasInicioInterior: 0,
          duracaoVigencia: 0,
          urlImagem: null,
          unidadeMedida: null,
          precoObrigatorio: false,
          materialComunicacaoTipo: null,
          valorDiagramacaoMaterialComunicacao: null,
        },
        {
          materialComunicacaoId: 26,
          nome: 'VÍDEO ATÉ 60 SEG.',
          descricao: 'VÍDEO ATÉ 60 SEG.',
          materialComunicacaoTipoId: 4,
          minimoProdutos: 0,
          maximoProdutos: 0,
          diasInicio: 0,
          diasFim: 0,
          ativo: true,
          possuiProducao: false,
          possuiFrete: false,
          diasInicioDigital: 0,
          diasInicioCapital: 0,
          diasInicioInterior: 0,
          duracaoVigencia: 0,
          urlImagem: null,
          unidadeMedida: null,
          precoObrigatorio: false,
          materialComunicacaoTipo: null,
          valorDiagramacaoMaterialComunicacao: null,
        },
      ],
    },
    {
      materialComunicacaoTipoId: 5,
      nome: 'MATERIAIS GRÁFICOS',
      ordem: 0,
      iconeMenu: null,
      title: null,
      ativo: false,
      materiaisComunicacao: [
        {
          materialComunicacaoId: 10,
          nome: 'ADESIVO',
          descricao: 'ADESIVO',
          materialComunicacaoTipoId: 5,
          minimoProdutos: 3,
          maximoProdutos: 8,
          diasInicio: 0,
          diasFim: 0,
          ativo: true,
          possuiProducao: true,
          possuiFrete: true,
          diasInicioDigital: 5,
          diasInicioCapital: 10,
          diasInicioInterior: 5,
          duracaoVigencia: 12,
          urlImagem: null,
          unidadeMedida: 'CM',
          precoObrigatorio: false,
          materialComunicacaoTipo: null,
          valorDiagramacaoMaterialComunicacao: null,
        },
        {
          materialComunicacaoId: 13,
          nome: 'BACKLIGHT',
          descricao: 'BACKLIGHT',
          materialComunicacaoTipoId: 5,
          minimoProdutos: 0,
          maximoProdutos: 0,
          diasInicio: 0,
          diasFim: 0,
          ativo: true,
          possuiProducao: true,
          possuiFrete: true,
          diasInicioDigital: 1,
          diasInicioCapital: 3,
          diasInicioInterior: 3,
          duracaoVigencia: 0,
          urlImagem: null,
          unidadeMedida: 'CM',
          precoObrigatorio: false,
          materialComunicacaoTipo: null,
          valorDiagramacaoMaterialComunicacao: null,
        },
        {
          materialComunicacaoId: 11,
          nome: 'BANNER',
          descricao: 'BANNER',
          materialComunicacaoTipoId: 5,
          minimoProdutos: 3,
          maximoProdutos: 8,
          diasInicio: 0,
          diasFim: 0,
          ativo: true,
          possuiProducao: true,
          possuiFrete: true,
          diasInicioDigital: 0,
          diasInicioCapital: 0,
          diasInicioInterior: 0,
          duracaoVigencia: 0,
          urlImagem: null,
          unidadeMedida: 'CM',
          precoObrigatorio: false,
          materialComunicacaoTipo: null,
          valorDiagramacaoMaterialComunicacao: null,
        },
        {
          materialComunicacaoId: 14,
          nome: 'CAPA DE ANTENA',
          descricao: 'CAPA DE ANTENA',
          materialComunicacaoTipoId: 5,
          minimoProdutos: 0,
          maximoProdutos: 0,
          diasInicio: 0,
          diasFim: 0,
          ativo: true,
          possuiProducao: true,
          possuiFrete: true,
          diasInicioDigital: 0,
          diasInicioCapital: 0,
          diasInicioInterior: 0,
          duracaoVigencia: 0,
          urlImagem: null,
          unidadeMedida: 'CM',
          precoObrigatorio: false,
          materialComunicacaoTipo: null,
          valorDiagramacaoMaterialComunicacao: null,
        },
        {
          materialComunicacaoId: 15,
          nome: 'CARTAZ',
          descricao: 'CARTAZ',
          materialComunicacaoTipoId: 5,
          minimoProdutos: 0,
          maximoProdutos: 0,
          diasInicio: 0,
          diasFim: 0,
          ativo: true,
          possuiProducao: true,
          possuiFrete: true,
          diasInicioDigital: 0,
          diasInicioCapital: 0,
          diasInicioInterior: 0,
          duracaoVigencia: 0,
          urlImagem: null,
          unidadeMedida: 'CM',
          precoObrigatorio: false,
          materialComunicacaoTipo: null,
          valorDiagramacaoMaterialComunicacao: null,
        },
        {
          materialComunicacaoId: 16,
          nome: 'FAIXA',
          descricao: 'FAIXA',
          materialComunicacaoTipoId: 5,
          minimoProdutos: 0,
          maximoProdutos: 0,
          diasInicio: 0,
          diasFim: 0,
          ativo: true,
          possuiProducao: true,
          possuiFrete: true,
          diasInicioDigital: 0,
          diasInicioCapital: 0,
          diasInicioInterior: 0,
          duracaoVigencia: 0,
          urlImagem: null,
          unidadeMedida: 'CM',
          precoObrigatorio: false,
          materialComunicacaoTipo: null,
          valorDiagramacaoMaterialComunicacao: null,
        },
        {
          materialComunicacaoId: 17,
          nome: 'FLYER',
          descricao: 'FLYER',
          materialComunicacaoTipoId: 5,
          minimoProdutos: 0,
          maximoProdutos: 0,
          diasInicio: 0,
          diasFim: 0,
          ativo: true,
          possuiProducao: true,
          possuiFrete: true,
          diasInicioDigital: 0,
          diasInicioCapital: 0,
          diasInicioInterior: 0,
          duracaoVigencia: 0,
          urlImagem: null,
          unidadeMedida: 'CM',
          precoObrigatorio: false,
          materialComunicacaoTipo: null,
          valorDiagramacaoMaterialComunicacao: null,
        },
        {
          materialComunicacaoId: 18,
          nome: 'SPLASH',
          descricao: 'SPLASH',
          materialComunicacaoTipoId: 5,
          minimoProdutos: 0,
          maximoProdutos: 0,
          diasInicio: 0,
          diasFim: 0,
          ativo: true,
          possuiProducao: true,
          possuiFrete: true,
          diasInicioDigital: 0,
          diasInicioCapital: 0,
          diasInicioInterior: 0,
          duracaoVigencia: 0,
          urlImagem: null,
          unidadeMedida: 'CM',
          precoObrigatorio: false,
          materialComunicacaoTipo: null,
          valorDiagramacaoMaterialComunicacao: null,
        },
        {
          materialComunicacaoId: 19,
          nome: 'STOPPER',
          descricao: 'STOPPER',
          materialComunicacaoTipoId: 5,
          minimoProdutos: 0,
          maximoProdutos: 0,
          diasInicio: 0,
          diasFim: 0,
          ativo: true,
          possuiProducao: true,
          possuiFrete: true,
          diasInicioDigital: 0,
          diasInicioCapital: 0,
          diasInicioInterior: 0,
          duracaoVigencia: 0,
          urlImagem: null,
          unidadeMedida: 'CM',
          precoObrigatorio: false,
          materialComunicacaoTipo: null,
          valorDiagramacaoMaterialComunicacao: null,
        },
        {
          materialComunicacaoId: 20,
          nome: 'TOTEM',
          descricao: 'TOTEM',
          materialComunicacaoTipoId: 5,
          minimoProdutos: 0,
          maximoProdutos: 0,
          diasInicio: 0,
          diasFim: 0,
          ativo: true,
          possuiProducao: true,
          possuiFrete: true,
          diasInicioDigital: 0,
          diasInicioCapital: 0,
          diasInicioInterior: 0,
          duracaoVigencia: 0,
          urlImagem: null,
          unidadeMedida: 'CM',
          precoObrigatorio: false,
          materialComunicacaoTipo: null,
          valorDiagramacaoMaterialComunicacao: null,
        },
        {
          materialComunicacaoId: 22,
          nome: 'TROFÉU',
          descricao: 'TROFÉU',
          materialComunicacaoTipoId: 5,
          minimoProdutos: 0,
          maximoProdutos: 0,
          diasInicio: 0,
          diasFim: 0,
          ativo: true,
          possuiProducao: true,
          possuiFrete: true,
          diasInicioDigital: 0,
          diasInicioCapital: 0,
          diasInicioInterior: 0,
          duracaoVigencia: 0,
          urlImagem: null,
          unidadeMedida: 'CM',
          precoObrigatorio: false,
          materialComunicacaoTipo: null,
          valorDiagramacaoMaterialComunicacao: null,
        },
        {
          materialComunicacaoId: 21,
          nome: 'WOBBLER',
          descricao: 'WOBBLER',
          materialComunicacaoTipoId: 5,
          minimoProdutos: 0,
          maximoProdutos: 0,
          diasInicio: 0,
          diasFim: 0,
          ativo: true,
          possuiProducao: true,
          possuiFrete: true,
          diasInicioDigital: 0,
          diasInicioCapital: 0,
          diasInicioInterior: 0,
          duracaoVigencia: 0,
          urlImagem: null,
          unidadeMedida: 'CM',
          precoObrigatorio: false,
          materialComunicacaoTipo: null,
          valorDiagramacaoMaterialComunicacao: null,
        },
      ],
    },
  ],
  tipoEntregas: [
    {
      entregaTipoId: 1,
      nome: 'LojaLoja',
      descricao: 'Entrega Loja a Loja',
      entregaFisica: true,
      entregaDigital: false,
      ativo: true,
    },
    {
      entregaTipoId: 2,
      nome: 'CentroDistribuicao',
      descricao: 'Entrega no Centro de Distribuição',
      entregaFisica: true,
      entregaDigital: false,
      ativo: true,
    },
    {
      entregaTipoId: 3,
      nome: 'Outro',
      descricao: 'Entrega em Outro Endereço',
      entregaFisica: true,
      entregaDigital: false,
      ativo: true,
    },
    {
      entregaTipoId: 4,
      nome: 'Digital',
      descricao: 'Digital',
      entregaFisica: false,
      entregaDigital: true,
      ativo: true,
    },
  ],
  clienteLayouts: {
    lojaId: 46233,
    layoutArtes: [
      {
        layoutArteId: 13,
        descricao: 'Qualy teste2',
        campanhaId: 1,
        urlImagem:
          'https://s3-us-west-2.amazonaws.com/midias-mccain1/HOM/LayoutArte/Fundo/qualy.jpg?20201215155720',
        urlImagemModelo:
          'https://s3-us-west-2.amazonaws.com/midias-mccain1/HOM/LayoutArte/Modelo/qualy.jpg?20201215155715',
        alturaMinima: 10,
        larguraMinima: 10,
        minimoProdutos: 1,
        maximoProdutos: 3,
        possuiProdutosEspecificos: true,
        possuiProdutosObrigatorios: true,
        vencimento: null,
        inicioVigencia: null,
        fimVigencia: null,
        ativo: true,
        campanha: null,
        layoutArteMateriaisComunicacao: [
          {
            layoutArteId: 13,
            materialComunicacaoId: 3,
            materialComunicacao: null,
          },
          {
            layoutArteId: 13,
            materialComunicacaoId: 4,
            materialComunicacao: null,
          },
          {
            layoutArteId: 13,
            materialComunicacaoId: 5,
            materialComunicacao: null,
          },
          {
            layoutArteId: 13,
            materialComunicacaoId: 6,
            materialComunicacao: null,
          },
          {
            layoutArteId: 13,
            materialComunicacaoId: 7,
            materialComunicacao: null,
          },
          {
            layoutArteId: 13,
            materialComunicacaoId: 8,
            materialComunicacao: null,
          },
          {
            layoutArteId: 13,
            materialComunicacaoId: 9,
            materialComunicacao: null,
          },
          {
            layoutArteId: 13,
            materialComunicacaoId: 10,
            materialComunicacao: null,
          },
          {
            layoutArteId: 13,
            materialComunicacaoId: 11,
            materialComunicacao: null,
          },
          {
            layoutArteId: 13,
            materialComunicacaoId: 13,
            materialComunicacao: null,
          },
          {
            layoutArteId: 13,
            materialComunicacaoId: 14,
            materialComunicacao: null,
          },
          {
            layoutArteId: 13,
            materialComunicacaoId: 15,
            materialComunicacao: null,
          },
          {
            layoutArteId: 13,
            materialComunicacaoId: 16,
            materialComunicacao: null,
          },
          {
            layoutArteId: 13,
            materialComunicacaoId: 17,
            materialComunicacao: null,
          },
          {
            layoutArteId: 13,
            materialComunicacaoId: 18,
            materialComunicacao: null,
          },
          {
            layoutArteId: 13,
            materialComunicacaoId: 19,
            materialComunicacao: null,
          },
          {
            layoutArteId: 13,
            materialComunicacaoId: 20,
            materialComunicacao: null,
          },
          {
            layoutArteId: 13,
            materialComunicacaoId: 21,
            materialComunicacao: null,
          },
          {
            layoutArteId: 13,
            materialComunicacaoId: 22,
            materialComunicacao: null,
          },
          {
            layoutArteId: 13,
            materialComunicacaoId: 23,
            materialComunicacao: null,
          },
          {
            layoutArteId: 13,
            materialComunicacaoId: 25,
            materialComunicacao: null,
          },
          {
            layoutArteId: 13,
            materialComunicacaoId: 26,
            materialComunicacao: null,
          },
        ],
        layoutArteRegionais: null,
        layoutArteProdutosEspecificos: [],
        layoutArteProdutosObrigatorios: [],
      },
    ],
  },
  materiaisCadastroItens: [],
  entregaLojasParticipantes: [],
  entregaCentroDistribuicao: {},
  entregaOutro: {},
  entregaDigital: {},
  produtos: [],
  produtoCategorias: [],
  unidades: [],
  motivos: [],
  extensoes: [],
  formaPagamentos: [],
  estados: [],
};

export {
  httpStatusCode,
  tabs,
  allTabs,
  entregaTabs,
  solicitacaoTabs,
  passos,
  entregaTipoNomes,
  cadastroItemKeys,
  unidadeMedidas,
  checkUrlImage,
  getStringFromDate,
  getDateFromString,
  getDateFromStringJson,
  compareString,
  getCampoObrigatorioClassName,
  testeModel,
};
